/* NavBar.css */

.navbar {
    position: fixed;
    display: flex;
    flex-direction: row;
    width: 100vw;
    margin: 0px;
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    /* background-color: rgb(31, 79, 143); */
    background-color: #343a40;
    padding: 10px 20px; 
    z-index: 999;
}
  
  .logo img {
    max-height: 40px; 
  }
  

  /* navbar.css */
.profile-section {
  display: flex;
  align-items: center;
  position: relative;
  float: right;
  margin-right: 40px;
  
}
span{
  color: white;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.dropdown-button {
  background: none;
  border: none;
  cursor: pointer;
  color: white;
 
}

.dropdown-menu {
  position: absolute;
  top: 130%;
  right: -10px;
  background-color: #343a40;
  border: 1px solid#343a40;
  border-radius: 5px;
  z-index: 10;
  color: white;
}

.logout-button {
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  width : 100px;
  height: 30px;
}
.logout-button:hover {
  background-color: #16181a;
}

@media screen and (max-width: 550px){
  .logo img {
    max-height: 30px; 
  }
}
@media screen and (max-width: 400px){
  .logo img {
    max-height: 25px; 
  }
}