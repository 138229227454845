.home-container {
  display: flex;
  flex-direction: column;
}

.home-card-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
  margin-top: 8% !important;
  flex: 1;
  margin-left: 3% !important;
}

.home-card {
  width: 300px;
  height: 270px;
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  cursor: pointer;
  border: 1.5px solid transparent;
  /* background-color: aqua; */
  transition: 0.3s;
}

.home-card:hover {
  transform: scale(1.05);
  border: 1.5px solid #343a40;
  cursor: pointer;
}

.home-card img {
  width: 100%;
  height: 170px; 
  object-fit: cover;
  margin-bottom: 0;
}

.details h5 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px; 
  color: #333;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  margin-top: 0;
}

.details h6 {
  font-size: 14px;
  font-weight: normal;
  color: #666;
  line-height: 1.4;
  margin-top: 0; 
  padding: 0 10px ; 
  margin-bottom: 5px;
}
