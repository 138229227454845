.card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-top: 18% !important;
    /* background-color: aqua; */
    flex: 1;
    margin-left: 0%;
  }
  
  .row1 {
    display: flex;
    gap: 20px;
  }
  .row2 {
    display: flex;
    gap: 20px;
  }
  
  .card {
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 35%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-left: 50px;
    padding-bottom: 20px;
    transition: 0.3s;
    overflow: hidden;
  }
  .card:hover{
    transform: scale(1.03);
  }
  
  .card img {
    /* Adjust image height as needed */
    width: 100%;
    object-fit: cover;
    height: 70%;
    margin-bottom: 10px;
  }
  p{
    width:auto;
    margin-left: 10px;
    text-align: left !important;
    padding: 0px;
   
  }
 
  @media screen and (max-width: 1000px) {
    .row1{
      display: flex;
      flex-direction: column;
      margin-top: 30%;
    }
    .row2{
      display:none;
      
    }
    .card{
      width: 60%;
    }

  }
  @media screen and (max-width: 900px){
    .row1{
      display: flex;
      flex-direction: column;
      margin-top: 35%;
    }
    .row2{
      display:none;
      
    }
    .card{
      width: 60%;
    }
  
  }
  @media screen and (max-width: 800px){
    .row1{
      display: flex;
      flex-direction: column;
      margin-top: 45%;
    }
    .card{
      width: 60%;
    }

  }
  @media screen and (max-width: 700px){
    .row1{
      display: flex;
      flex-direction: column;
      margin-top: 55%;
      margin-left: auto;
      margin-right: auto;
    }
    .card{
      width: 65%;
    }

  }
  @media screen and (max-width: 650px){
    .row1{
      display: none;
    }
  }