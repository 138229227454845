.onePgr-header{
    display: flex;
    flex-direction: column;
  
  }
  .onePgr-card-container {
      
      margin-left: 30px;
      margin-right: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      
     
    }
    .onePgr-header h1{
      text-align: left;
      margin-left: 3% !important;
      margin-top: 6%;
    }
    
    .onePgr-card {
      width: 300px;
      height: 270px;
      margin: 10px;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start; 
      border-radius: 10px;
      cursor: pointer;
      border: 1.5px solid transparent;
      transition: 0.3s;
      margin-bottom: 40px;
    }
    .onePgr-card:hover{
      transform: scale(1.05);
      border: 1.5px solid #343a40;
      cursor: pointer;
    } 
    
    .onePgr-card img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      margin-bottom: 0; 
    }
    
    .onePgr-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%; 
      padding-top: 0; 
    }
    
    .onePgr-card h2 {
      font-size: 18px;
      font-weight: bold;
      color: #333;
      padding: 10px; 
      margin-top: 0; 
      
    }
    
    .onePgr-card a {
      font-size: 14px;
      color: #666;
      line-height: 1.4;
      padding: 0 10px; 
      margin-top: 0; 
      margin-bottom: 10px; 
    }
    
    .onePgr- a:hover{
      color:rgb(13, 13, 161) ;
      cursor: pointer ;
    }
   