/* Signup.css */
.layout{
  display: flex;
  flex-direction: row;
  /* background-color: #cdd2d4; */
}
.signup-container {
    display: flex;
    justify-content:right;
    align-items: center;
    height: 100vh;
    flex: 1;
    /* background-color: #9ca6af; */
    margin-right: 7%;
    margin-top: 1.5%;
    
  }
  
  .signup-card {
    background-color: #ffffff;
    width: 300px;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 5%;
   
  }
  
  .signup-heading {
    font-weight: 500;
    text-align: left;
    margin-left: 10px;
    font-size: 1.75rem;
  }
  
  .inputs {
    margin-top: 30px;
  }
  
  .input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  label {
    text-align: left;
    margin-left: 10px;
    font-weight: 400;
  }
  
  label span {
    color: #9ca6af;
    font-size: 15px;
    font-weight: 400;
  }
  
  .input-with-icon {
    position: relative;
  }
  
  input {
    width: calc(100% - 30px);
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-top: 10px;
  }
  
  .icon {
    position: absolute;
    top: 45%;
    right: 15px;
    color: grey;
    font-size: 13px;
    cursor: pointer;
  }
  
  .signup-button {
    width: calc(100% - 6px);
    padding: 10px;
    margin-bottom: 15px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    background-color:  #343a40;
    color: white;
    cursor: pointer;
    font-weight: bold;
  }
  
  .other-login-options {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-top: 20px;
  }
  
  .other-login-button {
    display: flex;
    flex-direction: row;
    width: calc(100% - 25px);
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #cdd2d4;
    border-radius: 5px;
    background-color: #1f4f8f;
    color: white;
    cursor: pointer;
    font-weight: bold;
  }
  
  .google,
  .windows,
  .slack {
    background-color: white;
    color: rgba(0, 0, 0, .54);
    font-size: 16px;
  }
  
  .forgot-password {
    display: block;
    text-align: center;
    margin-bottom: 15px;
    text-decoration: none;
    color: #9ca6af;
    font-size: 12px;
    font-weight: 700;
  }
  
  .forgot-password:hover {
    text-decoration: underline;
  }
  
  .sign-in {
    text-decoration: none;
    color: #1f4f8f;
    font-weight: bold;
  }
  .sign-in::before{
    content: "";
    position: absolute;
    width: 0%;
    height: 2px;
    top: 109.3%;
    background-color:#1f4f8f;
    transition: 0.1s;
    border-radius: 1000000px;
  }
  .sign-in:hover::before{
    width: 38px;
  }
  
  
  
  p {
    font-size: 12px;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 1px;
    text-align: center;
  }
  
  .btn-logo {
    height: 18px;
    width: 18px;
    margin-left: 20%;
    margin-right: 3%;
  }
  