.linkedin-job-search{
  padding: 0;
  margin: 0;
    display: flex;
    flex-direction: column;
   width: 100vw ;
   height: 100vh;

}
.linkedinHead{
    color: #343a40;
    margin-top: 90px; 
}

/* Style the form */
#jobSearchForm {
  display: flex;
  flex-wrap: wrap;
    width: 65%;
    margin: auto;
    background-color:#fff;
    margin-top: 10px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Style form labels */
label {
    display: block;
    text-align: left;
    margin: 10px 0;
    margin-bottom: 0px;
    color: black;
    font-weight:500;
}
.jobType{
    width: 100%;
    color:black !important;
}
.jobType option{
    color:black ;
}

/* Style input fields and select */
.linkedin-input,
select {
    width: 40% !important;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    margin: 5px 0;
    border: 1px solid #343a40 !important;
    border: none;
    border-radius: 4px;
    background-color: #f5f2f2;
    color: black;
}
#location{
    width: 96.5%;
}
#companies, #jobRole{
    width: 98%;
}
#jobTags{
    width: 96.5%;
}

/* Style the "Search Jobs" button */
.linkedin-button{
    background-color: #343a40!important;
    border: 1px solid #343a40!important;
    color: white;
    border: none;
    border-radius: 4px !important;
    padding: 10px 40px;
    cursor: pointer;
    margin-top: 20px;
   margin-right: 0px;
    font-weight: bold;
    
    
}

.linkedin-button:hover {
    background-color:  #202225!important;
    color: #fff;
}



#jobResults{
   
    width:80%;
    margin: auto;
    margin-top:6%;
  
}


.job-entry{
    
        width: 90%;
        text-align: left;
        
        margin: 0 auto;
        margin-top: 30px;
        border-radius: 5px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        background-color:black;
        /* overflow-y: scroll; */
   
}
#jobDetailsHead{
    display: flex;
    align-items: center;
    text-align: left;
    background-color: #EA5340;
    color: #fff;
    padding: 0px;
    padding-left: 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.company-name{
    font-size: 25px !important;
    text-align: left !important;
    padding-left: 10px;
   
}
#titleloc{
    display: flex;
    flex-direction: column;
    margin-left: auto;
    height: auto;
    /* background-color: aquamarine; */
  
    padding: 10px;
    padding-right: 30px;
    text-align: right;
    
}
#jobDes{
    border: 1px solid #EA5340;
    border-bottom-left-radius:4px ;
    border-bottom-right-radius: 4px ;
    padding-left: 30px;
    text-align: left;
}


.job-table-container {
    margin: 50px; /* Adjust margin as needed */
    width:94% !important;
  }
  
  /* .job-table {
   width: 100% !important;
    border-collapse: collapse;
    color: black;
    overflow: hidden;
  }
  
  .job-table th, .job-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .job-table th {
    background-color: #343a40;
    color: #fff;
  } */
  

.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid  #343a40;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
  
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .error-message{
    color: black;
  }
  .export-csv{
    background-color: #343a40!important;
    border: 1px solid #343a40 !important;
    color: white;
    border: none;
    border-radius: 4px !important;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: auto !important;
  }

  /* dropdownStyles.css */
  .resultLimit {
    margin-top: 5px; /* Adjust the spacing */
    position: relative; /* Position relative to its container */
  }
  
  /* Add this for dropdown style */
  .resultLimit + select {
    position: absolute;
    top: 100%; /* Position below the field */
    left: 0;
    width: 100%;
    z-index: 1; /* Ensure dropdown is on top */
  }
  .linkedin-form-location, .linkedin-form-tags, .linkedin-form-type, .linkedin-form-limit{
    width: 45%;
    /* background-color: aqua; */
    margin:auto;
  }
  .linkedin-form-location input{
    width: 94% !important;
  }
 
  .linkedin-form-type select{
    width: 100% !important;
  }
  .linkedin-form-tags input{
    width: 94% !important;
  }
  .linkedin-form-limit select{
    width: 100% !important;
  }
  .linkedin-form-button{
   margin: auto;
    /* background-color: aqua; */
  }
  .equal-width {
    width: 25% !important;
    /* background-color: aqua; */
  }
  .overflow-ellipsis {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .job-table {
    
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    color: black;
    margin: 0 0 0 0 !important;
  }
  
  .job-table th,
  .job-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    width: 25%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .job-table th {
    background-color: #343a40;
    color: #fff;
  }
  
  .job-table td a {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .job-table td a:hover {
    text-decoration: underline;
  }
  
  /* .job-table td:hover {
    overflow: visible;
  } */
  
  /* .job-table td:hover a {
    white-space: normal;
    overflow: visible;
    text-overflow: initial;
  } */

  .job-table-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .pagination span{
    color: #202225;
  }
  .pagination{
    display: flex;
    flex-direction: row;
    margin: auto ;
    margin-top: 10px !important;
    gap:10px;
    margin-bottom: 10px;
  }